import { httpMethod, useApi } from './useApi';

export type ConditionType = {
  title: string;
  requirement: string;
  checklist: string[];
};

export type ProductSchemaType = {
  product: string;
  conditions: ConditionType[];
};

export const usePostChecklist = () => {
  const { call, data, ...rest } = useApi({
    authenticated: false,
    path: 'checklist',
    httpMethod: httpMethod.POST,
  });

  const responseData = data as ProductSchemaType;
  return { call, data: responseData, ...rest };
};
