import { httpMethod, useApi } from './useApi';

export type ImageFileType = {
  url: string;
  s3_key?: string;
  filename?: string;
  label?: string;
  image_base64?: string;
};

export type ResponseSchemaType = {
  classification: ImageFileType[];
};

export const usePostImageClassification = () => {
  const { call, data, ...rest } = useApi({
    authenticated: false,
    path: 'images/classification',
    httpMethod: httpMethod.POST,
  });

  const responseData = data as ResponseSchemaType;
  return { call, data: responseData, ...rest };
};
