import { httpMethod, useApi } from './useApi';

export type RequestSchemaType = { entity_ids: string[] };
export type ResponseSchemaType = string[];

export const useDeleteDocuments = () => {
  const { call, data, ...rest } = useApi({
    authenticated: true,
    path: 'documents',
    httpMethod: httpMethod.DELETE,
  });

  const callFunction = (body: RequestSchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchemaType;
  return { call: callFunction, data: responseData, ...rest };
};
