import React, { useRef, useState } from 'react';
import './ReportReview.css';

import Loading from '../../components/base/Loading';
import { useSessionState } from '../../utils/usePersistedState';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FileType } from '../../networking/usePostPDF';
import { PdfHighlight, SearchablePDFViewer } from '../../components/pdf/SearchablePDFViewer';
import { isNonEmpty } from '../../utils/arrayUtils';
import { TextReview } from './TextReview';
import { ReportReviewComponent } from './ReportReview';
import { HighlightedPDFViewer } from '../../components/pdf/HighlightedPDFViewer';
import { MenuProps } from 'antd';
import { SourcesView } from '../../components/pdf/SourcesView';

const ReviewView = () => {
  const [reviewDocuments, setReviewDocuments] = useSessionState<FileType[]>('reviewDocuments', []);
  const [viewDocument, setViewDocument] = useState<FileType>(null);
  const [reviewKBHighlights, setReviewKBHighlights] = useState<PdfHighlight[]>([]);
  const reviewPdfRef = useRef<HTMLDivElement>(null);

  const [reportDocuments, setReportDocuments] = useSessionState<FileType[]>('reportDocuments', []);
  const [reviewReportHighlights, setReviewReportHighlights] = useState<PdfHighlight[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const onViewDocument = (document: FileType) => {
    setViewDocument(document);
  };

  const showKBHighlights = (highlights: PdfHighlight[]) => {
    let document: FileType = null;
    if (isNonEmpty(highlights)) {
      for (let doc of reviewDocuments) {
        if (doc.id === highlights[0].document_id) {
          document = doc;
          break;
        }
      }
    }
    setReviewKBHighlights(highlights);
    setViewDocument(document);
  };

  const showReportHighlights = (highlights: PdfHighlight[]) => {
    let document: FileType = null;
    if (isNonEmpty(highlights)) {
      for (let doc of reportDocuments) {
        if (doc.id === highlights[0].document_id) {
          document = doc;
          break;
        }
      }
    }
    setReviewReportHighlights(highlights);
  };

  const isReportReview = currentTabIndex === 0;
  const isTextReview = currentTabIndex === 1;
  const dropdDownItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Report review',
      onClick: () => {
        setCurrentTabIndex(0);
      },
      style: { fontSize: 16 },
    },
    {
      key: '2',
      label: 'Text review',
      onClick: () => {
        setCurrentTabIndex(1);
      },
      style: { fontSize: 16 },
    },
  ];

  return (
    <div className={`review-container split`} style={{ height: '90vh' }}>
      <div
        className="review-section"
        // style={{
        // width: viewDocument ? '55%' : '65%',
        // }}
      >
        {isReportReview ? (
          <ReportReviewComponent
            kbDocuments={reviewDocuments}
            reportHighlights={reviewReportHighlights}
            setReportHighlights={showReportHighlights}
            setKBHighlights={showKBHighlights}
            setTextReview={() => setCurrentTabIndex(1)}
            dropdDownItems={dropdDownItems}
          />
        ) : isTextReview ? (
          <TextReview
            files={reviewDocuments}
            highlights={reviewKBHighlights}
            setHighlights={showKBHighlights}
            dropdDownItems={dropdDownItems}
          />
        ) : null}
      </div>

      <div
        ref={reviewPdfRef}
        className="pdf-review-section"
        style={{
          display: 'flex',
          flexGrow: 1,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRight: 0,
          borderTop: 0,
          borderBottom: 0,
          // width: viewDocument ? '45%' : '35%',
        }}
      >
        {viewDocument ? (
          isNonEmpty(reviewKBHighlights) ? (
            <HighlightedPDFViewer
              document={viewDocument}
              highlights={reviewKBHighlights}
              onClose={() => {
                setReviewKBHighlights([]);
                setViewDocument(null);
              }}
            />
          ) : (
            <SearchablePDFViewer document={viewDocument} onClose={() => setViewDocument(null)} />
          )
        ) : (
          <SourcesView documents={reviewDocuments} setDocuments={setReviewDocuments} onViewDocument={onViewDocument} />
        )}
      </div>
    </div>
  );
};
export default withAuthenticationRequired(ReviewView, {
  onRedirecting: () => <Loading />,
});
