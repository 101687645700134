import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InspectionEditor from '../../components/editor/InspectionEditor';
import Loading from '../../components/base/Loading';

const ReportGenerationView = InspectionEditor;

export default withAuthenticationRequired(ReportGenerationView, {
  onBeforeAuthentication: async () => {},
  onRedirecting: () => <Loading />,
  loginOptions: {},
});
