import { httpMethod, useApi } from './useApi';

export type ElementMetadata = {
  coordinates: { points: number[][]; layout_height: number; layout_width: number };
  languages: string[];
  page_number: number;
};

export type ChunkDocument = {
  document_id?: string;
  elements_metadata?: ElementMetadata[];
  id?: string;
  text: string;
};

export type ResponseSchema = {
  total: number;
  items: ChunkDocument[];
};

export const useGetDocumentChunks = ({ documentId }: { documentId?: string }) => {
  const { call, data, ...rest } = useApi({
    authenticated: true,
    path: 'document-chunks',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: {
      document_id: documentId,
    },
  });

  const responseData = data as ResponseSchema;
  return { call, data: responseData, ...rest };
};
