import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const usePersistedState = <T>(key, defaultValue): [state: T, setState: Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);

    if (storedValue) {
      try {
        // Attempt to parse JSON, if it fails, return storedValue (string)
        return JSON.parse(storedValue);
      } catch (error) {
        return storedValue;
      }
    }

    return defaultValue;
  });

  useEffect(() => {
    // Serialize the state before saving it to localStorage
    if (state !== null) {
      localStorage.setItem(key, JSON.stringify(state));
    } else {
      localStorage.removeItem(key);
    }
  }, [key, state]);

  return [state, setState];
};

export const useSessionState = <T>(key, defaultValue): [state: T, setState: Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const storedValue = sessionStorage.getItem(key);

    if (storedValue) {
      try {
        // Attempt to parse JSON, if it fails, return storedValue (string)
        return JSON.parse(storedValue);
      } catch (error) {
        return storedValue;
      }
    }

    return defaultValue;
  });

  useEffect(() => {
    // Serialize the state before saving it to sessionStorage
    if (state !== null) {
      sessionStorage.setItem(key, JSON.stringify(state));
    } else {
      sessionStorage.removeItem(key);
    }
  }, [key, state]);

  return [state, setState];
};
