/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import './ReportReview.css';

import { useSessionState } from '../../utils/usePersistedState';

import { FileType } from '../../networking/usePostPDF';
import { PdfHighlight, SearchablePDFViewer } from '../../components/pdf/SearchablePDFViewer';
import { isNonEmpty } from '../../utils/arrayUtils';
import { fromReviewResponseToReportResultNode, useGetAnomalies } from './useGetAnomalies';
import { ReportReview, usePostReview } from '../../networking/usePostReview';
import { Button } from '../../components/base/Button';
import Loading from '../../components/base/Loading';
import { HighlightedPDFViewer } from '../../components/pdf/HighlightedPDFViewer';
import FileDrop from '../../components/pdf/FileDrop';
import { ReviewTitle } from './TextReview';
import { ReportAnomalies } from './ReportAnomalies';

export const ReportReviewComponent = ({
  kbDocuments,
  reportHighlights,
  setReportHighlights,
  setKBHighlights,
  setTextReview,
  dropdDownItems,
}: {
  kbDocuments: FileType[];
  reportHighlights: PdfHighlight[];
  setReportHighlights;
  setKBHighlights;
  setTextReview;
  dropdDownItems;
}) => {
  const [filesToReview, setFilesToReview] = useSessionState<FileType[]>('filesToReview', []);
  const [currentReview, setCurrentReview] = useSessionState<ReportReview>('currentReportReview', null);

  const { isLoading: isLoadingReview, data: postReviewResponse, call: callStartReview } = usePostReview();
  const { reportResult, setReportResult } = useGetAnomalies({
    reviewResponse: postReviewResponse,
    setReviewResponse: setCurrentReview,
    setTextToReviewHighlights: setReportHighlights,
    setKnwoledgeBaseHighlights: setKBHighlights,
  });

  /**
   * After the filest to review have been uploaded,
   * start the actual review automatically
   */
  useEffect(() => {
    if (currentReview) {
      const reportResultNode = fromReviewResponseToReportResultNode({
        reviewResponse: currentReview,
      });
      setReportResult(reportResultNode.anomalyHighlights);
    }
  }, []);

  useEffect(() => {
    if (isNonEmpty(reportResult)) {
      setReportHighlights(reportResult[0].chunkToReviewHighlights);
      setKBHighlights(reportResult[0].highlights);
    }
  }, [reportResult]);

  // const onDeleteDocument = (deleteResponse) => {
  //   setFilesToReview(filesToReview.filter((doc) => !deleteResponse.includes(doc.id)));
  //   setCurrentReview(null);
  // };

  const startReview = async () => {
    if (haveReviewDocuments && isNonEmpty(kbDocuments)) {
      const documentIds = kbDocuments.filter((doc) => !doc.isExcluded).map((doc) => doc.id);
      callStartReview({ document_id: filesToReview[0].id, knowledge_base: documentIds });
    }
  };

  const haveReviewDocuments = isNonEmpty(filesToReview);

  if (isLoadingReview) {
    return <Loading absolute={false} />;
  }

  return (
    <div>
      {haveReviewDocuments && reportResult ? (
        <div style={{ display: 'flex', flexDirection: 'column', height: '92vh', gap: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <ReviewTitle title="Report to review" dropdDownItems={dropdDownItems}></ReviewTitle>
            <Button
              text="Exit review"
              style={{ width: 150, backgroundColor: 'yellow' }}
              onClick={() => {
                setKBHighlights([]);
                setReportHighlights([]);
                setReportResult(null);
                setCurrentReview(null);
              }}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              textAlign: 'center',
              paddingTop: 12,
            }}
          >
            {reportResult ? (
              <div style={{ width: '10%' }}>
                <ReportAnomalies
                  reportHighlights={reportResult}
                  setKBHighlights={setKBHighlights}
                  setReportHighlights={setReportHighlights}
                  showDescription={false}
                />
              </div>
            ) : null}
            <div style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>
              <HighlightedPDFViewer
                document={filesToReview[0]}
                highlights={reportHighlights}
                onClose={() => {
                  setKBHighlights([]);
                  setReportHighlights([]);
                  setReportResult(null);
                  setCurrentReview(null);
                }}
              />
            </div>
          </div>
        </div>
      ) : haveReviewDocuments ? (
        <div style={{ display: 'flex', flexDirection: 'column', height: '92vh' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <ReviewTitle title="Report to review" dropdDownItems={dropdDownItems}></ReviewTitle>
            <Button
              text="Start review"
              type="secondary"
              style={{ width: 150 }}
              disabled={!isNonEmpty(kbDocuments) || !haveReviewDocuments}
              onClick={startReview}
            />
          </div>
          <div>
            <div style={{ flexShrink: 0, textAlign: 'center', width: '80%', margin: 'auto', paddingTop: 12 }}>
              <SearchablePDFViewer document={filesToReview[0]} onClose={() => setFilesToReview([])} />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '90vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FileDrop
            text={'Upload report to review here'}
            setFiles={setFilesToReview}
            documentType={'report'}
            multiple={false}
            accept={'application/pdf'}
            styleText={{ fontSize: 16, fontWeight: 500 }}
            skipSection={{
              text: 'review a text',
              onClick: () => setTextReview(),
            }}
          />
        </div>
      )}
    </div>
  );
};
