import React, { CSSProperties } from 'react';
import { Text } from './Text';
import { Button as AntButton } from 'antd';

export const Button = ({
  type = 'primary',
  text,
  disabled,
  onClick,
  style,
  isLoading,
}: {
  type?: string;
  text: string;
  disabled?: boolean;
  onClick?: (event) => void;
  style?: CSSProperties;
  isLoading?: boolean;
}) => {
  const commonStyles = {
    borderRadius: 8,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 24,
    paddingRight: 24,
  };
  const buttonPrimaryStyle: CSSProperties = {
    ...commonStyles,
    backgroundColor: 'transparent',
    ...style,
  };
  const buttonSecondaryStyle: CSSProperties = {
    ...commonStyles,
    backgroundColor: !disabled ? '#1890ff' : null,
    color: 'white',
    ...style,
  };

  return (
    <AntButton
      style={type === 'primary' ? buttonPrimaryStyle : buttonSecondaryStyle}
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
    >
      <Text style={{ alignItems: 'center' }} value={text}></Text>
    </AntButton>
  );
};
