import { httpMethod, useApi } from './useApi';

export type FileType = {
  id: string;
  filename: string;
  url: string;
  s3_key?: string;
  created_utc: string;
  owner_id: string;
  isExcluded?: boolean;
};

export const usePostPDF = () => {
  const { call, data, ...rest } = useApi({
    authenticated: true,
    path: 'pdfs',
    httpMethod: httpMethod.POST,
  });

  const responseData = data as FileType[];
  return { call, data: responseData, ...rest };
};
