import { httpMethod, useApi } from './useApi';

export type ResponseSchema = any;

export type UpateSchemaType = { email?: string; name?: string };

export const useUpdateUser = () => {
  const { call, data, statusCode, error, ...rest } = useApi({
    authenticated: true,
    version: 1,
    path: 'users',
    httpMethod: httpMethod.PUT,
    body: {},
  });

  const callFunction = (body: UpateSchemaType) => {
    call(body);
  };

  const responseData = data as ResponseSchema;
  return { call: callFunction, statusCode, data: responseData, error, ...rest };
};
