import React from 'react';

import Loading from '../../components/base/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Text } from '../../components/base/Text';
import { UserView } from './UserView';
// import { OrganizationView } from './OrganizationView';

export const ProfileComponent = () => {
  return (
    <div className="flex-column" style={{ padding: '3vw', overflowY: 'auto', gap: 24 }}>
      <Text value={'Profile'} className="header1" />
      <UserView />
      {/* <OrganizationView /> */}
    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
