export const getFile = async (fileUrl: string) => {
  const response = await fetch(fileUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/pdf',
    },
  });
  const file = (await response.blob()) as File;
  return file;
};
