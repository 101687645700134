import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { SectionType, TextHighlight } from '../editor/types';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    marginVertical: 32,
  },
  section: {
    margin: 11,
    padding: 18,
    flexGrow: 1,
    gap: 10,
  },
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: 10,
  },
});

const buildSectionText = ({ text, highlights }: { text: string; highlights?: TextHighlight[] }) => {
  const parts = [];

  if (!highlights) {
    parts.push(
      <Text key={'normal-0'} style={{ fontSize: 14 }}>
        {text}
      </Text>
    );
    return parts;
  }

  let lastIndex = 0;

  highlights.sort((a, b) => a[0] - b[0]);
  highlights.forEach(({ start, end }, index) => {
    if (start > lastIndex) {
      parts.push(<Text key={`normal-${index}`}>{text.slice(lastIndex, start)}</Text>);
    }
    parts.push(
      <Text key={`highlight-${index}`} style={{ backgroundColor: 'yellow' }}>
        {text.slice(start, end)}
      </Text>
    );
    lastIndex = end;
  });
  if (lastIndex < text.length) {
    parts.push(<Text key="normal-end">{text.slice(lastIndex)}</Text>);
  }
  return parts;
};

const PDFSection = ({ section }: { section: SectionType }) => {
  // This will store the final JSX elements to render
  const parts = buildSectionText({ text: section.text, highlights: section.highlights });

  return (
    <View style={styles.section}>
      <Text style={{ fontSize: 20 }}>{section.title}</Text>
      <Text style={{ fontSize: 14 }}>{parts}</Text>

      <View style={styles.gallery}>
        {section.images.map((image, key) => {
          return <Image key={key} src={image.url} style={{ maxWidth: 150, maxHeight: 150 }}></Image>;
        })}
      </View>
    </View>
  );
};

// Create Document Component
// Generate PDF templates that are filled up of the information in the sections
// or read existing templates
/**
 * Group sections per page
 * @param {*} param0
 * @returns
 */
export const PDFDocument = ({ title, sections }: { title: string; sections: SectionType[] }) => {
  return (
    <Document>
      <Page size="A4" style={{ justifyContent: 'center', textAlign: 'center' }}>
        <View style={{ fontSize: 34 }}>
          <Text>{title}</Text>
        </View>
      </Page>

      {sections.map((section, key) => (
        <Page key={key} size="A4" style={styles.page}>
          <PDFSection key={key} section={section}></PDFSection>
        </Page>
      ))}
    </Document>
  );
};
