import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TextArea } from '../../base/TextArea';
import { AudioSnippet } from '../../AudioSnippet';
import { ImageSnippet } from '../../images/ImagesSnippet';
import { GalleryImages } from '../../images/ImageGallery';
import { SectionType } from '../types';
import { ValidationError, ValidationErrorType } from '../ValidationError';
import { TextInput } from '../../base/TextInput';
import { DeleteFilled } from '@ant-design/icons';
import { base64File, toBase64 } from '../../../utils/toBase64';
import { SectionChecklist } from './SectionChecklist';
import { useImageCaptioning } from '../../../networking/useImageCaptioning';
import Loading from '../../base/Loading';
import { ImageFileType } from '../../../networking/usePostImageClassification';

export type SectionText = {
  text: string;
  index: number;
};

export type SectionImage = ImageFileType & {
  caption?: string;
};

export const Section = forwardRef(function EditorSection(
  {
    section,
    isReview,
    onSelectAnomaly,
    onRemove,
  }: {
    section: SectionType;
    isReview?: boolean;
    onSelectAnomaly?: (text: string) => void;
    onRemove?: (text: string) => void;
  },
  ref
) {
  const [isEditing] = useState(true);
  const [sectionTitle, setSectionTitle] = useState(section.title || '');
  const [sectionText, setSectionText] = useState(section.text || '');
  const [sectionImages, setSectionImages] = useState<SectionImage[]>([]);
  const [checkListObj, setCheckListObj] = useState(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorType[]>([]);
  const { isLoading: isLoadingCaptioning, data: captioningResponse, call: callImageCaptioning } = useImageCaptioning();

  useImperativeHandle(ref, () => ({
    title: sectionTitle,
    requirement: section.requirement,
    images: sectionImages,
    text: sectionText,
    highlights: section.highlights,
    checklist: section.checklist,
    checkListObj: checkListObj,
  }));

  useEffect(() => {
    if (section.checklist) {
      // transform the list of strings into an object of the form { requirement: status }
      const itemsToCheck = section.checklist ? section.checklist.map((item) => [item, 'unchecked']) : null;
      setCheckListObj(Object.fromEntries(itemsToCheck));
    }
  }, [section.checklist]);

  useEffect(() => {
    if (section.title) {
      setSectionTitle(section.title);
    }
  }, [section.title]);

  useEffect(() => {
    const validationErrors: ValidationErrorType[] = [];
    if (isReview) {
      if (section.text && section.highlights && section.highlights.length > 0) {
        for (const highlight of section.highlights) {
          // Extract the text from highlights
          validationErrors.push({
            errorMessage: `Review warning: ${section.text.slice(highlight.start, highlight.end)}`,
            reason: highlight.reason,
          });
        }
      }
    }
    setValidationErrors(validationErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReview, section.highlights]);

  useEffect(() => {
    if (captioningResponse) {
      const newText = captioningResponse.caption;
      setSectionText(newText);
    }
  }, [captioningResponse]);

  /**
   * Function to upload the images to S3,
   * Get the captions of the images and update the 'ReportBlock' table
   * in the transactional DB.
   */
  const handleImageUpload = async (files: Blob[]) => {
    const imageUrls = files.map((file) => URL.createObjectURL(file));

    // TODO: uncomment and integrate with the API
    const promises = files.map(async (file) => toBase64(file));
    const filesBase64: base64File[] = await Promise.all(promises);
    const imagesBase64: string[] = filesBase64.map((fileBase64) => fileBase64.base64String);

    // analyse the images using AI and find anomalies
    // Build a generic caption from all the images
    // const caption = loremIpsum;
    const newImages: SectionImage[] = [];
    filesBase64.map((fileBase64, index) =>
      newImages.push({
        url: imageUrls[index],
        image_base64: imagesBase64[index],
      })
    );
    setSectionImages((prevImages) => [...prevImages, ...newImages]);
    await callImageCaptioning({ base64_images: imagesBase64 });
  };

  /**
   * Temporary function to upload a sample file for demo purposes
   */
  const setSampleImage = async () => {
    const response = await fetch('https://relyant-public-assets.s3.us-west-2.amazonaws.com/pressure.jpeg', {
      method: 'GET',
    });
    const blob = await response.blob();
    handleImageUpload([blob]);
  };

  if (isLoadingCaptioning) {
    return <Loading text="Generating text from images" style={{ paddingTop: 24, fontSize: 14 }} absolute={false} />;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 12,
            gap: 20,
          }}
        >
          {isEditing ? (
            <TextInput value={sectionTitle} setText={setSectionTitle} style={{ fontWeight: 700, fontSize: 16 }} />
          ) : (
            <div style={{ fontWeight: 700, fontSize: 16 }}>{sectionTitle}</div>
          )}

          <div className="" style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
            <ImageSnippet handleImageUpload={handleImageUpload} setDisplayText={setSectionText}></ImageSnippet>
            <AudioSnippet setDisplayText={setSectionText}></AudioSnippet>
            <div className="clickable delete-icon">
              <DeleteFilled
                style={{
                  fontSize: 18,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  alignItems: 'center',
                }}
                onClick={() => onRemove(section.title)}
              />
            </div>
          </div>
        </div>

        {checkListObj ? <SectionChecklist checkListObj={checkListObj}></SectionChecklist> : null}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <GalleryImages images={sectionImages}></GalleryImages>
        </div>
        <div
          className="clickable"
          style={{ color: 'blue', marginBottom: 8, fontSize: 12 }}
          onClick={() => setSampleImage()}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          Sample image
        </div>
        <div style={{ display: 'flex' }}>
          <TextArea text={sectionText} setText={setSectionText}></TextArea>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'right',
            paddingTop: 12,
            color: 'red',
            fontSize: 14,
          }}
        >
          {validationErrors.length > 0
            ? validationErrors.map((err, key) => {
                return (
                  <ValidationError
                    key={key}
                    errorMessage={err.errorMessage}
                    reason={err.reason}
                    onSelectAnomaly={onSelectAnomaly}
                  ></ValidationError>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
});
