import React, { useState } from 'react';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Feedback.css';

export type FeedbackType = null | 0 | 10;

const Feedback = ({ value, onFeedback }: { value?: FeedbackType; onFeedback: (type: FeedbackType) => void }) => {
  const [feedback, setFeedback] = useState(value);

  const handleThumbClick = (type: FeedbackType) => {
    setFeedback(type);
    onFeedback(type);
  };

  return (
    <div className="feedback-container">
      <div
        className={`thumb thumb-up ${feedback === 10 ? 'active' : ''}`}
        onClick={() => {
          return feedback === 10 ? handleThumbClick(null) : handleThumbClick(10);
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon icon={faThumbsUp} />
      </div>
      <div
        className={`thumb thumb-down ${feedback === 0 ? 'active' : ''}`}
        onClick={() => {
          return feedback === 0 ? handleThumbClick(null) : handleThumbClick(0);
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon icon={faThumbsDown} />
      </div>
    </div>
  );
};

export default Feedback;
