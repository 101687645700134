import React, { CSSProperties, ReactNode } from 'react';

export const Text = ({
  value,
  className,
  style,
}: {
  value: string | ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  return (
    <div
      className={className}
      style={{
        whiteSpace: 'pre-line',
        ...style,
      }}
    >
      {value}
    </div>
  );
};
