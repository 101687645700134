import { httpMethod, useApi } from './useApi';
import { ChunkDocument, ElementMetadata } from './useGetDocumentChunks';

export type RequestSchemaType = { document_id?: string; text_to_review?: string; knowledge_base: string[] };

export type Chunk = {
  chunk_id: string;
  description: string;
  document_id: string;
  elements_metadata: ElementMetadata[];
};

export type ReportResult = {
  chunk_to_review: ChunkDocument;
  anomalies: Chunk[];
};

export type ReportReview = {
  id: string;
  user_id: string;
  document_id?: string;
  knowledge_base: string[];
  created_utc: Date;
  result: ReportResult[];
};

export const usePostReview = () => {
  const { call, data, ...rest } = useApi({
    authenticated: true,
    path: `reviews`,
    httpMethod: httpMethod.POST,
  });

  const callFunction = (body: RequestSchemaType) => {
    call(body);
  };

  const responseData = data as ReportReview;
  return { call: callFunction, data: responseData, ...rest };
};
