import React from 'react';

export const SectionChecklist = ({ checkListObj }: { checkListObj }) => {
  const colors = {
    unchecked: 'white',
    pass: 'green',
    fail: 'red',
  };
  const itemsToCheck = [];
  for (const key in checkListObj) {
    itemsToCheck.push({ name: key, status: checkListObj[key] });
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        alignItems: 'center',
      }}
    >
      {itemsToCheck.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              fontWeight: 300,
              backgroundColor: colors[item.status],
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 16,
              padding: 8,
              fontSize: 11,
            }}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};
