import React from 'react';
import { SectionImage } from '../editor/Section/Section';

export const GalleryImages = ({
  images,
  includeFileNames = false,
}: {
  images: SectionImage[];
  includeFileNames?: boolean;
}) => {
  const groups = images.reduce(function (r, a) {
    r[a.label] = r[a.label] || [];
    r[a.label].push(a);
    return r;
  }, Object.create(null));
  return (
    <>
      {Object.keys(groups).map((key, index) => {
        const images = groups[key];
        return (
          <div style={{ marginTop: '20px' }} key={index}>
            {key !== 'undefined' && <div style={{ fontSize: 18, fontWeight: 700 }}>{key}</div>}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {images.map((image, index) => (
                <div key={index} style={{ margin: '10px' }}>
                  <img src={image.url} alt={`Uploaded ${index}`} style={{ maxWidth: '200px', maxHeight: '120px' }} />
                  {includeFileNames && <div>{image.filename}</div>}
                </div>
              ))}
            </div>
          </div>
        );
      })}
      {/* <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
        {images.map((image, index) => (
          <div key={index} style={{ margin: '10px' }}>
            <img src={image.url} alt={`Uploaded ${index}`} style={{ maxWidth: '200px', maxHeight: '120px' }} />
          </div>
        ))}
      </div> */}
    </>
  );
};
