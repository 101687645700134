import { httpMethod, useApi } from './useApi';
import { ChunkDocument } from './useGetDocumentChunks';

export type Sentence = {
  sentence: string;
  chunks: ChunkDocument[];
};

export type QueryDocumentResponseSchema = {
  query_id: string;
  answer: Sentence[];
};

type RequestPostSchemaType = {
  query: string;
  document_ids: string[];
};

type RequestPutSchemaType = {
  id: string;
  query?: string;
  feedback_score?: number;
  knowledge_base?: string[];
};

export const useQueryDocument = () => {
  const { call, data, ...rest } = useApi({
    authenticated: true,
    version: 2,
    path: 'documents/query',
    httpMethod: httpMethod.POST,
  });

  const callFunction = (body: RequestPostSchemaType) => {
    call(body);
  };

  const responseData = data as QueryDocumentResponseSchema;
  return { call: callFunction, data: responseData, ...rest };
};

export const usePutQuery = () => {
  const { call, data, ...rest } = useApi({
    authenticated: true,
    version: 1,
    path: 'documents/queries',
    httpMethod: httpMethod.PUT,
  });

  const callFunction = (body: RequestPutSchemaType) => {
    call(body);
  };

  const responseData = data as QueryDocumentResponseSchema;
  return { call: callFunction, data: responseData, ...rest };
};
