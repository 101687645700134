import { Checkbox, Tooltip } from 'antd';
import React, { CSSProperties } from 'react';

export const CheckBox = ({
  text,
  tooltip,
  checked,
  onChange,
  style,
}: {
  text?: string;
  tooltip?: string;
  checked: boolean;
  onChange;
  style?: CSSProperties;
}) => {
  return (
    <Tooltip title={tooltip}>
      <div>
        <Checkbox value={text} onChange={() => onChange(!checked)} style={{ ...style }} checked={checked}>
          {text}
        </Checkbox>
      </div>
    </Tooltip>
  );
};
