import React, { CSSProperties } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

export const TextInput = ({
  value,
  placeholder,
  minLines = 1,
  maxLines = 1,
  setText,
  style,
}: {
  value: string;
  placeholder?: string;
  minLines?: number;
  maxLines?: number;
  setText;
  style?: CSSProperties;
}) => {
  return (
    <TextArea
      value={value}
      placeholder={placeholder}
      autoSize={{ minRows: minLines, maxRows: maxLines }}
      onChange={(e) => setText(e.target.value)}
      style={{
        borderRadius: 2,
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        backgroundColor: 'transparent',
        ...style,
      }}
    />
  );
};
