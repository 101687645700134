/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useEffect, useState } from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Button, Upload } from 'antd';
import { toBase64 } from '../../utils/toBase64';
import { Text } from '../base/Text';
import { usePostPDF } from '../../networking/usePostPDF';
import Loading from '../base/Loading';
import { usePostImageClassification } from '../../networking/usePostImageClassification';

const { Dragger } = Upload;

type FileDropSkipSection = {
  text: string;
  onClick: () => void;
};

/**
 * Component to upload documents in bulk.
 * if setImages is defined, the 'accept' prop should contain the 'image/*' content type
 *
 * @param param0
 * @returns
 */
export default function FileDrop({
  text,
  loadingText,
  setFiles,
  setImages,
  documentType = null,
  multiple = false,
  accept = 'application/pdf',
  useButton = false,
  loadingOnlyText = false,
  styleText,
  skipSection,
}: {
  text?: string;
  loadingText?: string;
  setFiles?;
  setImages?;
  documentType?: string;
  multiple?: boolean;
  accept?: string;
  useButton?: boolean;
  loadingOnlyText?: boolean;
  styleText?: CSSProperties;
  skipSection?: FileDropSkipSection;
}) {
  const [fileList, setFileList] = useState<any[]>([]);
  const { isLoading, data: uploadResponse, call } = usePostPDF();
  const {
    isLoading: isLoadingImgClassification,
    data: imgClassificationResponse,
    call: callImgClassification,
  } = usePostImageClassification();

  const splitFiles = async (files: File[]): Promise<{ pdfs: File[]; images: File[] }> => {
    const pdfs = [];
    const images = [];

    for (let file of files) {
      if (file.type === 'application/pdf') {
        pdfs.push({ base64_document: (await toBase64(file as File)).base64String, filename: file.name });
      } else if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type)) {
        images.push({ base64_document: (await toBase64(file as File)).base64String, filename: file.name });
      }
    }
    return { pdfs, images };
  };

  useEffect(() => {
    async function uploadDocuments() {
      const { pdfs, images } = await splitFiles(fileList);
      // We can opt-in or out from the image classification
      callImgClassification({ documents: images });
      call({ documents: pdfs, documents_type: documentType });
    }
    if (fileList.length > 0) {
      uploadDocuments();
    }
  }, [fileList]);

  useEffect(() => {
    if (imgClassificationResponse && uploadResponse) {
      if (setImages) {
        setImages(imgClassificationResponse.classification);
      }
      if (setFiles) {
        setFiles(uploadResponse);
      }
    }
  }, [uploadResponse, imgClassificationResponse]);

  const props: UploadProps = {
    accept: accept,
    multiple: multiple,
    showUploadList: false,
    beforeUpload: async (file, fileList) => {
      try {
        setFileList(fileList);
      } catch (error) {
        message.error(`Failed to convert files to base64.`);
      }
      return false;
    },
    onChange(info) {},
    onDrop(event) {},
  };

  if (isLoading || isLoadingImgClassification) {
    return (
      <Loading
        text={loadingText || 'Uploading documents...'}
        style={{ paddingTop: 24, fontSize: 14, ...styleText }}
        absolute={false}
        onlyText={loadingOnlyText}
      />
    );
  }

  if (useButton) {
    return (
      <Upload {...props} fileList={fileList}>
        <Button icon={<UploadOutlined />}>{text === null ? 'Upload documents' : text}</Button>
      </Upload>
    );
  }

  return (
    <>
      <Dragger {...props} fileList={fileList}>
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
            {/* <UploadOutlined /> */}
          </p>
          <div></div>
          <Text
            className="ant-upload-text"
            value={text || 'Click or drag file to this area to upload'}
            style={{ ...styleText }}
          />
          <p style={{ paddingTop: 10 }} className="ant-upload-hint">
            {'The documents will only be accessible to you'}
          </p>
        </>
      </Dragger>
      {skipSection && !isLoading && (
        <span style={{ padding: 10 }}>
          or
          <span
            className="clickable blue"
            style={{ marginLeft: 4, fontSize: 14, fontWeight: 400 }}
            onClick={skipSection.onClick}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            {skipSection.text}
          </span>
        </span>
      )}
    </>
  );
}
