import configLocalJson from './auth_config.local.json';
import configDevJson from './auth_config.dev.json';
import configJson from './auth_config.json';

export function getConfig() {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return {
        domain: configJson.domain,
        clientId: configJson.clientId,
        audience: configJson.audience || undefined,
        apiOrigin: configJson.apiOrigin,
      };
    case 'dev':
      return {
        domain: configDevJson.domain,
        clientId: configDevJson.clientId,
        audience: configDevJson.audience || undefined,
        apiOrigin: configDevJson.apiOrigin,
      };
    default:
      return {
        domain: configLocalJson.domain,
        clientId: configLocalJson.clientId,
        audience: configLocalJson.audience || undefined,
        apiOrigin: configLocalJson.apiOrigin,
      };
  }
}
