/* eslint-disable react/prop-types */
import React from 'react';
import ReactMarkdown from 'react-markdown';

export const MarkdownText = ({ markdownText, embeddedNode }: { markdownText; embeddedNode? }) => {
  return (
    <div>
      {/* Customize rendering with the 'components' prop */}
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => (
            <p {...props}>
              {props.children}
              {embeddedNode && embeddedNode}
            </p>
          ),
        }}
      >
        {markdownText}
      </ReactMarkdown>
    </div>
  );
};
