import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { FileType } from '../../networking/usePostPDF';
import { Tooltip } from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export type PdfHighlight = {
  document_id: string;
  text?: string;
  hoverText?: string;
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
  pageWidth?: number;
  pageHeight?: number;
  color?: string;
};

function range(start, end) {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export function SearchablePDFViewer({
  maxHeight,
  containerWidth,
  fileUrl,
  document,
  onClose,
}: {
  maxHeight?: number | string;
  containerWidth?: number;
  fileUrl?: string;
  document?: FileType;
  onClose?;
}) {
  const pageRefs = useRef({});
  const containerRef = useRef(null);
  const [pageWidth, setPageWidth] = useState(2048);
  const [scale, setScale] = useState(1); // Zoom scale
  // const [pageNumberHighlight, setPageNumberHighlight] = useState(null);
  // const [pdfHighlights, setPdfHighlights] = useState<PdfHighlight[]>([]);
  const [{ numPages, fromPagesRendered, toPagesRendered }, setState] = useState({
    numPages: null,
    fromPagesRendered: null,
    toPagesRendered: null,
  });

  const onRenderSuccess = () => {
    setState((prevState) => ({
      numPages: prevState.numPages,
      fromPagesRendered: prevState.fromPagesRendered,
      toPagesRendered: prevState.toPagesRendered + 1,
    }));
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setState({
      numPages,
      fromPagesRendered: 0,
      toPagesRendered: 0,
    });
  }

  const updateScale = () => {
    if (containerRef.current) {
      const viewerWidth = containerWidth || containerRef.current.clientWidth;
      const scaleFactor = viewerWidth / pageWidth;
      setScale(scaleFactor);
    }
  };

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const zoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

  // Adjust the scale based on the width of the viewer (initial zoom)
  useEffect(() => {
    updateScale();

    // Update the scale when the window is resized
    window.addEventListener('resize', updateScale);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateScale);
  }, [pageWidth]);

  const pagesRenderedPlusOne = Math.min(toPagesRendered + 1, numPages);
  return (
    <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <div className="blue" style={{ fontWeight: 500 }}>
        File: {document.filename}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: 20,
          paddingBottom: 10,
          gap: 20,
        }}
      >
        <div style={{ display: 'flex', marginLeft: 'auto', gap: 10 }}>
          <ZoomOutOutlined onClick={zoomOut} disabled={scale <= 0.5} />
          <ZoomInOutlined onClick={zoomIn} disabled={scale >= 3} />
        </div>
        <Tooltip>
          <div
            className="clickable"
            style={{ color: 'red', fontWeight: 700 }}
            onClick={onClose}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            X
          </div>
        </Tooltip>
      </div>

      <div
        style={{
          maxHeight: maxHeight || '100vh',
          overflow: 'auto',
        }}
      >
        <Document file={document?.url || fileUrl} onLoadSuccess={onDocumentLoadSuccess} loading={''}>
          {range(fromPagesRendered, toPagesRendered).map((el, index) => {
            const pNum = el + 1;
            const isCurrentlyRendering = pagesRenderedPlusOne === pNum;
            const isLastPage = numPages === pNum;
            const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;
            return (
              <div
                ref={(el) => {
                  pageRefs.current[pNum] = el;
                }}
                key={`page_${pNum}`}
                style={{ position: 'relative', marginBottom: '20px' }}
              >
                <Page
                  id={`${pNum}`}
                  pageNumber={pNum}
                  loading={''}
                  onRenderSuccess={needsCallbackToRenderNextPage ? onRenderSuccess : null}
                  scale={scale}
                  width={pageWidth}
                />
              </div>
            );
          })}
        </Document>
      </div>
    </div>
  );
}
