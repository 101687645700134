import React, { CSSProperties } from 'react';
import { Button } from './Button';

type TabType = {
  id: string;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?;
};

export const HeaderTabs = ({
  tabs,
  currentTabIndex,
  setCurrentTabIndex,
  style,
  styleTabs,
}: {
  tabs: TabType[];
  currentTabIndex: number;
  setCurrentTabIndex;
  style?: CSSProperties;
  styleTabs?: CSSProperties;
}) => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '10%',
        padding: 8,
        ...style,
      }}
    >
      {tabs.map((tab, index) => {
        return (
          <Button
            text={tab.label}
            disabled={tab.disabled}
            style={{
              backgroundColor: tab.isActive ? 'yellow' : 'whitesmoke',
              padding: 6,
              width: 100,
              borderRadius: 4,
              opacity: 1,
              textAlign: 'center',
              borderWidth: 2.5,
              borderStyle: 'solid',
              borderColor: index === currentTabIndex ? 'blue' : 'transparent',
              ...styleTabs,
            }}
            key={index}
            onClick={() => {
              if (tab.onClick) tab.onClick();
              else setCurrentTabIndex(index);
            }}
          />
        );
      })}
    </div>
  );
};
