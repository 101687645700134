const hexToRgb = (hex) => ({
  r: parseInt(hex.slice(1, 3), 16),
  g: parseInt(hex.slice(3, 5), 16),
  b: parseInt(hex.slice(5, 7), 16),
});

export const getRandomColor = (opacity: number = 0.5) => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  const { r, g, b } = hexToRgb(color);
  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
};

// Helper function to convert RGB to hex
const rgbToHex = ({ r, g, b }) => `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`.toUpperCase();

// Main function to get random variation of the input color
export function getRandomColorVariation(color?: string, opacity: number = 0.5) {
  const mainColor = color || getRandomColor();
  const { r, g, b } = hexToRgb(mainColor);

  // Apply random variation to each RGB channel
  const randomize = (channel) => Math.min(255, Math.max(0, channel + Math.floor(Math.random() * 50 - 25)));

  const newColor = {
    r: randomize(r),
    g: randomize(g),
    b: randomize(b),
  };

  return `rgb(${newColor.r}, ${newColor.g}, ${newColor.b}, ${opacity})`;
}
