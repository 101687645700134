import { useEffect, useState } from 'react';
import './ReportReview.css';
import { PdfHighlight } from '../../components/pdf/SearchablePDFViewer';
import { Chunk, ReportReview } from '../../networking/usePostReview';
import { ChunkDocument } from '../../networking/useGetDocumentChunks';

export type ReportHighlights = {
  description: string;
  chunkToReviewHighlights: PdfHighlight[];
  highlights: PdfHighlight[];
};

const getHighlightCoordinates = (points) => {
  const x = points[0][0];
  const y = points[0][1];
  const height = points[1][1] - points[0][1];
  const width = points[2][0] - points[1][0];
  return { x, y, width, height };
};

export const fromChunkToHighlights = (chunk: Chunk | ChunkDocument, chunkDescription?: string): PdfHighlight[] => {
  const highlights: PdfHighlight[] = [];
  if (!chunk.elements_metadata) {
    return highlights;
  }
  for (let element of chunk.elements_metadata) {
    highlights.push({
      document_id: chunk.document_id,
      page: element.page_number,
      ...getHighlightCoordinates(element.coordinates.points),
      pageHeight: element.coordinates.layout_height,
      pageWidth: element.coordinates.layout_width,
      hoverText: chunkDescription,
    });
  }
  return highlights;
};

export const fromReviewResponseToReportResultNode = ({ reviewResponse }: { reviewResponse: ReportReview }) => {
  const resultReport = reviewResponse.result;
  const anomalies = [];
  for (let chunkReviewResult of resultReport) {
    if (chunkReviewResult.anomalies.length > 0) {
      for (let anomaly of chunkReviewResult.anomalies) {
        anomalies.push({
          description: anomaly.description,
          chunkToReviewHighlights: fromChunkToHighlights(chunkReviewResult.chunk_to_review, anomaly.description),
          highlights: fromChunkToHighlights(anomaly, anomaly.description),
        });
      }
    }
  }
  return { anomalyHighlights: anomalies };
};

export const useGetAnomalies = ({
  reviewResponse,
  setReviewResponse,
}: {
  reviewResponse: ReportReview;
  setReviewResponse;
  setTextToReviewHighlights?;
  setKnwoledgeBaseHighlights;
}) => {
  const [reportResult, setReportResult] = useState<ReportHighlights[]>(null);

  /**
   * Handle the review response
   */
  useEffect(() => {
    if (reviewResponse && reviewResponse.result && reviewResponse.result.length) {
      let reportResultNode = fromReviewResponseToReportResultNode({
        reviewResponse,
      });

      setReportResult(reportResultNode.anomalyHighlights);
      setReviewResponse(reviewResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewResponse]);

  return { reportResult, setReportResult };
};
