import React from 'react';

import Loading from '../../components/base/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import DocumentChunking from './DocumentChunking/DocumentChunking';

const AdminView = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  if (user.email !== 'lucagiacomelli1604@gmail.com') {
    return null;
  }

  return (
    <div className={``}>
      <DocumentChunking></DocumentChunking>
    </div>
  );
};

export default withAuthenticationRequired(AdminView, {
  onRedirecting: () => <Loading />,
});
