/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode, useState } from 'react';

export type ValidationErrorType = {
  errorMessage: string;
  reason?: string;
  onSelectAnomaly?: (text: string) => void;
};

const Tooltip = ({ text, children }: { text: string; children: ReactNode }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            marginBottom: '8px',
            padding: '8px',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            zIndex: '1000',
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export const ValidationError = ({ errorMessage, reason, onSelectAnomaly }: ValidationErrorType) => {
  return (
    <div onFocus={() => {}}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 5 }}>
        <div>{errorMessage}</div>
        <Tooltip text={'See it in SOP'}>
          <div
            className="clickable"
            onClick={() => {
              onSelectAnomaly(reason);
            }}
            style={{ backgroundColor: 'transparent', borderWidth: 0, color: 'black', textDecoration: 'underline' }}
          >
            {'(See reference)'}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
