/* eslint-disable */
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

import './ImagesSnippet.css';
import { loremIpsum } from '../../utils/contentData';
import { useImageCaptioning } from '../../networking/useImageCaptioning';
import { base64File, toBase64 } from '../../utils/toBase64';

/**
 * Snipper to upload an image and get the captioning.
 * The caption will come from the API.
 * @param {*}
 * @returns
 */
export const ImageSnippet = ({ handleImageUpload, setDisplayText }) => {
  const fileInputRef = useRef(null);
  const [captionImages, setCaptionImages] = useState('');
  const [index, setIndex] = useState(0);

  // typewriting effect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (index === captionImages.length) {
        clearTimeout(timeoutId);
        return;
      }
      setDisplayText((prevText) => prevText + captionImages.charAt(index));
      setIndex((prevIndex) => prevIndex + 1);
    }, 20);
  }, [captionImages, setDisplayText, index]);

  
  const handleUpload = async (event) => {
    const files: Blob[] = Array.from(event.target.files);
    handleImageUpload(files)
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <div onClick={handleButtonClick} className="clickable upload-images-icon">
        <FontAwesomeIcon icon={faUpload} />
      </div>
      <input
        type="file"
        accept="image/*"
        multiple
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleUpload}
      />
    </div>
  );
};
