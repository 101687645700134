import { httpMethod, useApi } from './useApi';
import { FileType } from './usePostPDF';

export type ResponseSchema = {
  total: number;
  items: FileType[];
};

export const useGetDocuments = ({ document_ids, type }: { document_ids?: string[]; type?: string }) => {
  const { call, data, error, ...rest } = useApi({
    authenticated: true,
    path: 'documents',
    httpMethod: httpMethod.GET,
    body: {},
    queryParams: {
      ids: document_ids,
    },
  });

  const responseData = data as ResponseSchema;
  return { call, data: responseData, error, ...rest };
};
