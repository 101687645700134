import { httpMethod, useApi } from './useApi';

export type ImageCaptioiningSchemaType = { caption: string };

export const useImageCaptioning = () => {
  const { call, data, ...rest } = useApi({
    authenticated: false,
    path: 'images/captioning',
    httpMethod: httpMethod.POST,
  });

  const responseData = data as ImageCaptioiningSchemaType;
  return { call, data: responseData, ...rest };
};
