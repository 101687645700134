/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import './ResearchView.css';

import { TextForm } from '../../components/base/TextForm';
import Loading from '../../components/base/Loading';
import { usePutQuery, useQueryDocument } from '../../networking/useQueryDocument';
import ChatWindow from '../../components/chat/ChatWindow';
import { ChatMessage, MessageType, SenderEnum } from '../../components/chat/Message';
import { useSessionState } from '../../utils/usePersistedState';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FileType } from '../../networking/usePostPDF';
import { HighlightedPDFViewer } from '../../components/pdf/HighlightedPDFViewer';
import { PdfHighlight, SearchablePDFViewer } from '../../components/pdf/SearchablePDFViewer';
import { SourcesView } from '../../components/pdf/SourcesView';
import { fromChunkToHighlights } from '../ReportReview/useGetAnomalies';
import { isNonEmpty } from '../../utils/arrayUtils';

import { NumberedDot } from '../../icons/NumberedDot';
import { Button } from '../../components/base/Button';
import { MarkdownText } from '../../components/base/MarkdownText';

const DEFAULT_SUMMARY_QUESTION = 'What is the summary of the document?';

const ResearchView = () => {
  const [researchDocuments, setResearchDocuments] = useSessionState<FileType[]>('researchDocuments', []);
  const [viewDocument, setViewDocument] = useState<FileType>(null);

  const [query] = useState<string>('');
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [chatMessages, setChatMessages] = useSessionState<ChatMessage[]>('researchMessages', []);

  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);

  const { isLoading: isLoadingQuery, statusCode, data: queryResponse, call: callQueryDocument } = useQueryDocument();
  const { call: callUpdateQuery } = usePutQuery();

  /**
   * Transform a chat Message into a Message to render,
   * with references to the chunks
   * @param param0
   * @returns
   */
  const fromChatMessageToMessage = (answer: ChatMessage): MessageType => {
    if (!isNonEmpty(answer.sentences)) {
      return {
        text: <div>{'Could not answer the question'}</div>,
        sender: SenderEnum.bot,
      };
    }

    const chunkIds = [];
    for (let i = 0; i < answer.sentences.length; i++) {
      const sentence = answer.sentences[i];
      for (let chunk of sentence.chunks) {
        chunkIds.push(chunk.id);
      }
    }
    const setChunkIds = new Set(chunkIds);
    const uuidToIntMap = new Map<string, number>();
    let index = 0;
    setChunkIds.forEach((str) => {
      uuidToIntMap.set(str, index + 1);
      index++;
    });

    return {
      messageId: answer.messageId,
      text: (
        <div>
          {answer.sentences.map((s, index) => {
            return (
              <div key={index} style={{ marginTop: 4 }}>
                <span>
                  <MarkdownText
                    markdownText={s.sentence}
                    embeddedNode={s.chunks.map((chunk, index) => {
                      const highlights = fromChunkToHighlights(chunk);
                      return (
                        <NumberedDot
                          key={index}
                          text={uuidToIntMap.get(chunk.id)}
                          tooltip={'See Source'}
                          onClick={() => showHighlights(highlights)}
                        />
                      );
                    })}
                  />
                </span>
              </div>
            );
          })}
        </div>
      ),
      sender: answer.sender,
      feedback: answer.feedback,
      onFeedback: answer.onFeedback,
    };
  };

  const addMessageFeedback = (chatMessage: ChatMessage) => {
    if (chatMessage.sender === SenderEnum.bot) {
      chatMessage.onFeedback = (score) => {
        callUpdateQuery({
          id: chatMessage.messageId,
          feedback_score: score,
        });
        setChatMessages((prevChatMessages) =>
          prevChatMessages.map((cm) => (cm.messageId === chatMessage.messageId ? { ...cm, feedback: score } : cm))
        );
      };
    }
  };

  useEffect(() => {
    if (isNonEmpty(chatMessages)) {
      const messages = [];
      for (let chatMessage of chatMessages) {
        addMessageFeedback(chatMessage);
        messages.push(fromChatMessageToMessage(chatMessage));
      }
      setMessages(messages);
    }
  }, []);

  useEffect(() => {
    if (statusCode === 413) {
      const errorMessage = { text: 'Please select one source document for the summary', sender: SenderEnum.bot };
      setMessages([...messages, errorMessage]);
      return;
    }
    if (queryResponse) {
      const messageId = queryResponse.query_id;
      const chatMessage = {
        messageId,
        sentences: queryResponse.answer,
        sender: SenderEnum.bot,
      };

      addMessageFeedback(chatMessage);
      const newMessage = fromChatMessageToMessage(chatMessage);
      setMessages([...messages, newMessage]);
      setChatMessages([...chatMessages, chatMessage]);
    }
  }, [queryResponse]);

  /**
   * Submit the query to the API, and asynchronously invoke the endpoint
   * @param query
   * @returns
   */
  const submitQuery = async (query) => {
    const documentIds = researchDocuments.filter((doc) => !doc.isExcluded).map((doc) => doc.id);
    if (documentIds.length === 0) {
      alert('Please select at least one source document for the summary');
      return;
    }

    const newMessage = { text: query, sender: SenderEnum.user };
    if (query === DEFAULT_SUMMARY_QUESTION && documentIds.length !== 1) {
      const errorMessage = { text: 'Please select one source document for the summary', sender: SenderEnum.bot };
      setMessages([...messages, newMessage, errorMessage]);
      return;
    }

    setMessages([...messages, newMessage]);
    setChatMessages([
      ...chatMessages,
      {
        sentences: [{ sentence: query, chunks: [] }],
        sender: SenderEnum.user,
      },
    ]);
    callQueryDocument({ query: query, document_ids: documentIds });
  };

  const onViewDocument = (document: FileType) => {
    setViewDocument(document);
  };

  const showHighlights = (highlights: PdfHighlight[]) => {
    let document: FileType = null;
    if (isNonEmpty(highlights)) {
      for (let doc of researchDocuments) {
        if (doc.id === highlights[0].document_id) {
          document = doc;
          break;
        }
      }
    }
    setHighlights(highlights);
    setViewDocument(document);
  };

  return (
    <div className={`query-container split`} style={{ height: '90vh' }}>
      <div className="query-section">
        <ChatWindow
          isLoading={isLoadingQuery}
          messages={messages}
          recommendations={
            [
              // { text: 'What is the summary of the document?', onClick: getSummary },
              // { text: 'Is this report compliant with the industry standard?' },
              // { text: 'What are the mandatory requirements for the inspection?' },
            ]
          }
        />

        <div>
          <Button
            text={DEFAULT_SUMMARY_QUESTION}
            onClick={() => submitQuery(DEFAULT_SUMMARY_QUESTION)}
            disabled={researchDocuments.length === 0 || isLoadingQuery}
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              marginTop: 12,
              marginBottom: 12,
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: 12,
              borderWidth: 0,
            }}
          />
          <TextForm
            disabled={researchDocuments.length === 0 || isLoadingQuery}
            initialText={query}
            handleSubmit={submitQuery}
          ></TextForm>
        </div>
      </div>

      <div
        className="pdf-section query-pdf-section"
        style={{ borderStyle: 'solid', borderWidth: 1, borderRight: 0, borderTop: 0, borderBottom: 0 }}
      >
        {viewDocument ? (
          isNonEmpty(highlights) ? (
            <HighlightedPDFViewer
              document={viewDocument}
              highlights={highlights}
              onClose={() => {
                setHighlights([]);
                setViewDocument(null);
              }}
            />
          ) : (
            <SearchablePDFViewer document={viewDocument} onClose={() => setViewDocument(null)} />
          )
        ) : (
          <SourcesView
            documents={researchDocuments}
            setDocuments={setResearchDocuments}
            onViewDocument={onViewDocument}
          />
        )}
      </div>
    </div>
  );
};

export default withAuthenticationRequired(ResearchView, {
  onRedirecting: () => <Loading />,
});
