import React, { Fragment } from 'react';

const Support = () => (
  <Fragment>
    Send us an email to{' '}
    <a href="mailto: support@relyany.ai" target="blank">
      support@relyant.ai
    </a>
    <hr />
  </Fragment>
);

export default Support;
