import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import './InspectionEditor.css';

import { PDFDocument } from '../pdf/PDFDocument';
import { Section } from './Section/Section';
import { SectionType, TextHighlight } from './types';
import { EditorHeader, EditorHeaderType } from './EditorHeader';
import { PDFViewer } from '@react-pdf/renderer';
import { intialHeaderState } from '../../utils/contentData';
import Loading from '../base/Loading';

import { toBase64 } from '../../utils/toBase64';
import { usePersistedState } from '../../utils/usePersistedState';
import { usePostChecklist } from '../../networking/usePostChecklist';
import { FileContainer } from '../pdf/FileContainer';
import { getFile } from '../../networking/getFile';
import { ImageFileType } from '../../networking/usePostImageClassification';
import { HeaderTabs } from '../base/HeaderTabs';
import { FileType } from '../../networking/usePostPDF';

/**
 * Editor for product inspections
 * @returns
 */
const InspectionEditor = () => {
  const [files, setFiles] = usePersistedState<FileType[]>('reportDocuments', []);
  const [currentIndex, setCurrentIndex] = usePersistedState<number>('reportDocumentIndex', 0);
  const [images, setImages] = usePersistedState<ImageFileType[]>('reportImages', []);

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  let sectionsRef: MutableRefObject<SectionType[]> = useRef([]);
  const [sections, setSections] = usePersistedState<SectionType[]>('sections', []);

  const [headerState, setHeaderState] = useState<EditorHeaderType>(intialHeaderState);
  const [searchText, setSearchText] = useState<string>();

  const { isLoading, data, call } = usePostChecklist();

  const [{ isPreview, isReview }, setEditorState] = useState({
    isPreview: false,
    isReview: false,
  });

  const fetchSectionsData = async () => {
    if (files && files.length > 0) {
      const file = await getFile(files[0].url);
      const base64File = await toBase64(file);
      if (sections.length === 0) {
        call({ base64_document: base64File.base64String });
      }
    }
  };

  // TODO
  // chunk the documents
  // extract the fillable sections of the documents

  useEffect(() => {
    if (data) {
      setSections([...(data.conditions as SectionType[])]);
    }
  }, [data]);

  /**
   * Extract the sections from the first uploaded document
   * TODO: consider all the uploadeded files
   */
  // useEffect(() => {
  //   fetchSectionsData(files);
  // }, [files]);

  const showPreview = async () => {
    // the shallow copy is important to re-render the component
    setSections([...sectionsRef.current.filter((section) => section !== null)]);
    setEditorState((prev) => ({
      ...prev,
      isPreview: !prev.isPreview,
    }));
  };

  const removeSection = (id) => {
    sectionsRef.current = sectionsRef.current.filter((section) => section !== null);
    const newSections = sections.filter((section) => section.title !== id);
    sectionsRef.current = sectionsRef.current.filter((section) => section.title !== id);
    setSections(newSections);
  };

  const resetFiles = () => {
    setFiles([]);
    setSections([]);
    setImages([]);
    setEditorState({
      isReview: false,
      isPreview: false,
    });
  };

  const showReview = async () => {
    const sectionWithHighlights: SectionType[] = [];
    for (const section of sectionsRef.current) {
      // TODO: call an API to review the document
      // and return the indexes where the section text is not
      // compliant to the standards
      const highlights: TextHighlight[] = [];
      if (!isReview) {
        const textsToHighlight = ['20 pascals', '100 kPa'];
        for (const textToHighlight of textsToHighlight) {
          if (section.text.indexOf(textToHighlight) > -1) {
            highlights.push({
              text: textToHighlight,
              start: section.text.indexOf(textToHighlight),
              end: section.text.indexOf(textToHighlight) + textToHighlight.length,
              reason: 'pressure conditions',
            });
          }
        }
      }
      section.highlights = highlights;
      sectionWithHighlights.push(section);
    }
    setSections(sectionWithHighlights);
    setEditorState((prev) => ({
      ...prev,
      isReview: !prev.isReview,
    }));
    setSearchText(null);
  };

  const onSelectAnomaly = async (text: string) => {
    setSearchText(text);
  };

  if (isLoading) {
    return <Loading text="Generating report template from document" />;
  }

  const isPreviewMode = isPreview && sections.length;
  const isFileUploaded = files && files.length > 0;
  const showSearchablePDFViewer = isFileUploaded && !isPreviewMode;

  return (
    <div className={`editor-container split`}>
      <div className="editor-section" style={{ height: '90vh', overflow: 'auto' }}>
        <HeaderTabs
          tabs={[
            {
              id: 'tab-generate-sections',
              label: 'Generate sections',
              disabled: files.length === 0,
              onClick: () => fetchSectionsData(),
            },
            {
              id: 'tab-generate-report',
              label: 'Preview report',
              disabled: sections.length === 0,
              onClick: () => showPreview(),
              isActive: isPreview,
            },
            {
              id: 'tab-review',
              label: 'Review',
              disabled: sections.length === 0,
              onClick: () => showReview(),
              isActive: isReview,
            },
          ]}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
          style={{ backgroundColor: 'transparent', justifyContent: 'flex-start', gap: 10 }}
          styleTabs={{ width: 150, borderColor: 'black', borderWidth: 0.5 }}
        />

        <div className="editor-section-header">
          <EditorHeader headerState={headerState} setHeaderState={setHeaderState}></EditorHeader>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            paddingTop: 64,
          }}
        >
          {sections
            ? sections.map((section, key) => {
                return (
                  <div key={key} className="section-container" style={{ width: '100%' }}>
                    <Section
                      ref={(el) => (sectionsRef.current[key] = el as SectionType)}
                      section={section}
                      isReview={isReview}
                      onSelectAnomaly={onSelectAnomaly}
                      onRemove={removeSection}
                    ></Section>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div
        className="pdf-section"
        style={{ borderStyle: 'solid', borderWidth: 1, borderRight: 0, borderTop: 0, borderBottom: 0 }}
      >
        <div className="" style={{ width: '100%' }}>
          {isPreviewMode ? (
            <PDFViewer style={{ width: '100%', height: 1000 }}>
              {/* TODO: group sections per page before creating the PDF */}
              <PDFDocument title={headerState.title} sections={sections} />
            </PDFViewer>
          ) : (
            <>
              <FileContainer
                showSearchablePDFViewer={showSearchablePDFViewer}
                documents={files}
                setDocuments={setFiles}
                images={images}
                setImages={setImages}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                resetFiles={resetFiles}
                uploadMultiple={true}
                accept={'application/pdf, image/jpeg, image/png, image/jpg, image/gif'}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InspectionEditor;
