import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { InfoCircleFilled, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button } from '../base/Button';
import { PdfHighlight } from './SearchablePDFViewer';
import './HighlightedPDFViewer.css';
import { FileType } from '../../networking/usePostPDF';
import { Tooltip } from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Highlight = ({ highlight, scale, randomColors }: { highlight: PdfHighlight; scale; randomColors }) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          left: highlight.x * scale,
          top: highlight.y * scale,
          width: highlight.width * scale,
          height: highlight.height * scale,
          backgroundColor: randomColors ? highlight.color : 'rgba(255, 255, 0, 0.5)',
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      />
      {/* <div
        style={{
          position: 'absolute',
          left: highlight.x * scale - 20,
          top: highlight.y * scale - 0,
          color: 'red',
        }}
        onClick={() => console.log('highlight clicked')}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <InfoCircleFilled />
      </div> */}
    </div>
  );
};

export function HighlightedPDFViewer({
  maxHeight,
  fileUrl,
  document,
  highlights,
  randomColors = false,
  onClose,
}: {
  maxHeight?: number | string;
  fileUrl?: string;
  document?: FileType;
  highlights: PdfHighlight[];
  randomColors?: boolean;
  onClose?;
}) {
  const viewerRef = useRef(null);
  const [{ numPages }, setState] = useState({
    numPages: null,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(2048);
  const [scale, setScale] = useState(1); // Zoom scale
  const [pdfHighlights, setPdfHighlights] = useState<PdfHighlight[]>([]);

  useEffect(() => {
    if (highlights && highlights.length > 0) {
      setPdfHighlights(highlights);

      // we can default from the first highlight
      setPageNumber(highlights[0].page);
      setPageWidth((prev) => highlights[0].pageWidth || prev);
    }
  }, [highlights]);

  // Functions to handle page navigation
  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setState({
      numPages,
    });
  }

  const updateScale = () => {
    if (viewerRef.current) {
      const viewerWidth = viewerRef.current.clientWidth;
      const scaleFactor = viewerWidth / pageWidth;
      setScale(scaleFactor);
    }
  };

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1); // Cap the zoom level at 3x
  };

  const zoomOut = () => {
    setScale((prevScale) => prevScale - 0.1); // Minimum zoom level at 0.5x
  };

  // Adjust the scale based on the width of the viewer (initial zoom)
  useEffect(() => {
    updateScale();

    // Update the scale when the window is resized
    window.addEventListener('resize', updateScale);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateScale);
  }, [pageWidth]);

  return (
    <div ref={viewerRef} style={{ paddingTop: 10 }}>
      <div className="blue" style={{ fontWeight: 500 }}>
        File: {document.filename}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            gap: 10,
          }}
        >
          <Button
            onClick={goToPreviousPage}
            disabled={pageNumber <= 1}
            style={{ border: 0, backgroundColor: 'transparent' }}
            text="<"
          />
          <span>
            Page {pageNumber} of {numPages}
          </span>

          <Button
            onClick={goToNextPage}
            disabled={pageNumber >= numPages}
            style={{ border: 0, backgroundColor: 'transparent' }}
            text=">"
          />
        </div>

        <div style={{ display: 'flex', marginLeft: 'auto', gap: 10 }}>
          <ZoomOutOutlined onClick={zoomOut} disabled={scale <= 0.5} />
          <ZoomInOutlined onClick={zoomIn} disabled={scale >= 3} />
          <Tooltip>
            <div
              className="clickable"
              style={{ color: 'red', fontWeight: 700 }}
              onClick={onClose}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              X
            </div>
          </Tooltip>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          maxHeight: maxHeight || 700,
          overflow: 'auto',
          justifyContent: 'center',
        }}
      >
        <Document file={document?.url || fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <div key={`page_${pageNumber}`} style={{ position: 'relative', marginBottom: '20px' }}>
            <Page id={`${pageNumber}`} pageNumber={pageNumber} loading={''} scale={scale} width={pageWidth} />
            {pdfHighlights
              .filter((highlight) => highlight.page === pageNumber)
              .map((highlight, idx) => (
                <div key={idx}>
                  <Highlight highlight={highlight} scale={scale} randomColors={randomColors}></Highlight>
                </div>
              ))}
          </div>
        </Document>
      </div>
    </div>
  );
}
