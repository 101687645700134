import React from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../../components/base/Loading';
import { DocumentChunksView } from './DocumentChunksView';

const DocumentChunking = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '50%' }}>
        <DocumentChunksView keyView={'1'} />
      </div>
      <div style={{ width: '50%' }}>
        <DocumentChunksView keyView={'2'} />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(DocumentChunking, {
  onRedirecting: () => <Loading />,
});
