import React, { useState } from 'react';

import { Text } from './Text';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { DatePicker } from 'antd';

export const FormView = ({
  headerForm,
  data,
  displayMapping,
  onCreate,
  onUpdate,
  isLoading = false,
}: {
  headerForm: string;
  displayMapping: Record<string, string>;
  data?: Record<string, unknown>;
  onCreate?;
  onUpdate?: (data) => void;
  isLoading?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [entity, setEntity] = useState<Record<string, unknown>>(data || {});

  return (
    <div>
      <div className="flex-row" style={{ justifyContent: 'space-between' }}>
        <Text value={headerForm} className="header2" />
        <div className="flex-row" style={{ gap: 12, maxHeight: 32 }}>
          {isEditing ? (
            <>
              <Button text={'Cancel'} onClick={() => setIsEditing(false)} />
              <Button
                text={'Save'}
                onClick={() => {
                  const fieldsToUpdate = Object.keys(displayMapping).reduce((acc, key) => {
                    if (entity.hasOwnProperty(key)) {
                      acc[key] = entity[key];
                    }
                    return acc;
                  }, {});
                  onUpdate(fieldsToUpdate);
                  setIsEditing(false);
                }}
                isLoading={isLoading}
              />
            </>
          ) : data && onUpdate ? (
            <Button text={'Edit'} onClick={() => setIsEditing(true)} />
          ) : onCreate ? (
            <Button text={'Create'} onClick={() => onCreate(entity)} />
          ) : null}
        </div>
      </div>
      {/* <hr style={{ color: 'grey', height: 1, width: '100%', padding: 0, margin: 2 }}></hr> */}
      <div className="flex-column" style={{ gap: 16, paddingTop: 16, textAlign: 'left' }}>
        {Object.keys(displayMapping).map((key, index) => {
          const label = displayMapping[key];
          const value = entity[key];

          return (
            <div key={index} className="flex-row" style={{ gap: 12 }}>
              <Text style={{ alignSelf: 'center', width: 200, fontWeight: 700 }} value={`${label}:`} />

              {typeof value === 'string' ? (
                !isEditing ? (
                  <Text value={value}></Text>
                ) : (
                  <TextInput
                    value={value as string}
                    setText={(text) =>
                      setEntity((entity) => ({
                        ...entity,
                        [key]: text,
                      }))
                    }
                    style={{ maxWidth: 512 }}
                  />
                )
              ) : typeof value === 'number' ? null : value instanceof Date ? (
                <DatePicker
                  value={value}
                  onChange={(date) =>
                    setEntity((entity) => ({
                      ...entity,
                      [key]: date,
                    }))
                  }
                />
              ) : null}
            </div>
          );
        })}
      </div>
      {!data && <div>{`No ${headerForm} have been created. Create a new one now!`}</div>}
    </div>
  );
};
