import React from 'react';
import './ReportReview.css';
import { ReportHighlights } from './useGetAnomalies';
import { Text } from '../../components/base/Text';
import { WarningOutlined } from '@ant-design/icons';
import { isNonEmpty } from '../../utils/arrayUtils';
import { Tooltip } from 'antd';

export const ReportAnomalies = ({
  reportHighlights,
  setKBHighlights,
  setReportHighlights,
  showDescription = true,
  maxHeight,
}: {
  reportHighlights: ReportHighlights[];
  setKBHighlights;
  setReportHighlights?;
  showDescription?: boolean;
  maxHeight?: number | string;
}) => {
  if (!isNonEmpty(reportHighlights)) {
    console.log('return 0 anomalies');
    return <>{`0 anomalies`}</>;
  }

  return (
    <div>
      <div style={{ textAlign: 'center', fontWeight: 700 }}>
        {`${reportHighlights.length} anomal${reportHighlights.length > 1 ? 'ies' : 'y'}`}
      </div>

      <div
        style={{
          padding: 12,
          fontSize: 14,
          overflow: 'auto',
          maxHeight: maxHeight || 600,
        }}
      >
        {/* Create an AnomalyEntry node that has a state 'active' when it is clicked */}
        {reportHighlights.map((anomaly, index) => {
          return (
            <div key={index} style={{ marginTop: 14 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: 20 }}>
                <Tooltip title={anomaly.description}>
                  {/* <Tooltip title={'Show source'}> */}
                  <WarningOutlined
                    className="clickable"
                    style={{ color: 'red', fontSize: 16 }}
                    onClick={() => {
                      setKBHighlights(anomaly.highlights);
                      if (setReportHighlights) setReportHighlights(anomaly.chunkToReviewHighlights);
                    }}
                  />
                </Tooltip>

                {showDescription ? (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: 20 }}>
                    <div>{`${anomaly.description}`}</div>
                    <Text
                      className="clickable"
                      value="Source"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => {
                        setKBHighlights(anomaly.highlights);
                        if (setReportHighlights) setReportHighlights(anomaly.chunkToReviewHighlights);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
