import React from 'react';
import './InspectionEditor.css';
import { TextInput } from '../base/TextInput';
import { Text } from '../base/Text';
import { v4 as uuidv4 } from 'uuid';

export type EditorHeaderType = {
  inspectionId?: string;
  title: string;
  writer?: string;
  contributors?: string[];
  date?: Date;
};

// TODO: create a form from the JSON object/schema/type
//  example like the following
const UIHeaderStateMapping = {
  'Inspection ID': {
    value: 'inspectionId',
    default: uuidv4(),
  },
  Title: {
    value: 'title',
    default: undefined,
  },
  Writer: {
    value: 'writer',
    default: undefined,
  },
  Date: {
    value: 'date',
    default: new Date().toLocaleDateString(),
  },
};

export const EditorHeader = ({ headerState, setHeaderState }: { headerState: EditorHeaderType; setHeaderState }) => {
  // TODO: add the headerState here instead of the Editor and pass it using the useRef
  // the same way Sections work
  return (
    <div className="flex-column" style={{ gap: 10, textAlign: 'left' }}>
      {Object.keys(UIHeaderStateMapping).map((key, index) => {
        return (
          <div key={index} className="flex-row" style={{ gap: 12 }}>
            <Text style={{ alignSelf: 'center', width: 160, fontWeight: 700 }} value={`${key}:`} />
            <TextInput
              style={{ fontSize: 16 }}
              value={
                headerState[UIHeaderStateMapping[key].value]
                  ? headerState[UIHeaderStateMapping[key].value] instanceof Date
                    ? headerState[UIHeaderStateMapping[key].value].toLocaleDateString()
                    : headerState[UIHeaderStateMapping[key].value]
                  : UIHeaderStateMapping[key].default
              }
              setText={(text) =>
                setHeaderState((header) => ({
                  ...header,
                  title: text,
                }))
              }
            />
          </div>
        );
      })}
    </div>
  );
};
