import React, { useEffect, useState } from 'react';

import { usePersistedState } from '../../../utils/usePersistedState';
import { FileContainer } from '../../../components/pdf/FileContainer';

import { FileType } from '../../../networking/usePostPDF';
import { useGetDocuments } from '../../../networking/useGetDocuments';
import { PdfHighlight } from '../../../components/pdf/SearchablePDFViewer';
import { isNonEmpty } from '../../../utils/arrayUtils';
import { useGetDocumentChunks } from '../../../networking/useGetDocumentChunks';
import { fromChunkToHighlights } from '../../ReportReview/useGetAnomalies';
import { HighlightedPDFViewer } from '../../../components/pdf/HighlightedPDFViewer';
import { getRandomColor } from '../../../utils/styleUtils';

export const DocumentChunksView = ({ keyView }: { keyView: string }) => {
  const [files, setFiles] = usePersistedState<FileType[]>(`adminDocuments${keyView}`, []);
  const [currentIndex, setCurrentIndex] = usePersistedState<number>(`adminDocumentIndex${keyView}`, 0);
  const [reviewKBHighlights, setReviewKBHighlights] = useState<PdfHighlight[]>([]);
  const { data: documentsResponse, call: callGetDocuments } = useGetDocuments({
    document_ids: files.map((f) => f.id),
  });
  const { data: documentChunksResponse, call: callGetDocumentChunks } = useGetDocumentChunks({
    documentId: documentsResponse && documentsResponse.items.length > 0 ? documentsResponse.items[0].id : undefined,
  });

  /**
   * Fetch all the documents of the user when the page is rendered
   */
  useEffect(() => {
    if (files && files.length > 0) {
      callGetDocuments();
    }
  }, [files]);

  useEffect(() => {
    if (documentsResponse) {
      callGetDocumentChunks();
    }
  }, [documentsResponse]);

  useEffect(() => {
    if (documentChunksResponse) {
      const highlights = [];
      const chunks = documentChunksResponse.items;
      for (let chunk of chunks) {
        const chunkColor = getRandomColor();
        for (let highlight of fromChunkToHighlights(chunk)) {
          // highlight['color'] = getRandomColorVariation(chunkColor);
          highlight.color = chunkColor;
          highlights.push(highlight);
        }
      }
      setReviewKBHighlights(highlights);
    }
  }, [documentChunksResponse]);

  return (
    <div style={{ width: '80%', margin: 'auto' }}>
      <FileContainer
        showSearchablePDFViewer={false}
        showHeaderTabs={true}
        enableSampleDocument={false}
        documents={isNonEmpty(files) ? files : []}
        setDocuments={setFiles}
        resetFiles={() => setFiles([])}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        fileDropText={'Upload a document to review the chunks'}
        uploadMultiple={false}
        accept={'application/pdf'}
        onDeleteDocument={() => {
          setReviewKBHighlights([]);
          setFiles([]);
        }}
      />
      {documentsResponse && documentsResponse.items.length > 0 && (
        <HighlightedPDFViewer
          document={documentsResponse.items[currentIndex]}
          highlights={reviewKBHighlights}
          randomColors={true}
        />
      )}
    </div>
  );
};
