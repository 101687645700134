import React, { CSSProperties, useState } from 'react';

export const TextForm = ({
  initialText,
  placeholder,
  handleSubmit,
  disabled = false,
  minRows = 1,
  maxRows = 3,
  characterLimit = 200,
  clearOnSubmit = true,
  style,
}: {
  initialText?: string;
  placeholder?: string;
  handleSubmit;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  characterLimit?: number;
  clearOnSubmit?: boolean;
  style?: CSSProperties;
}) => {
  const [rowsArea, setRowsArea] = useState(minRows);
  const [text, setText] = useState(initialText || '');
  const CHARACTER_LIMIT = characterLimit;

  const handleInputChange = (event) => {
    let newText = event.target.value;
    if (newText.length >= CHARACTER_LIMIT) {
      newText = newText.substring(0, CHARACTER_LIMIT);
    }
    setText(newText);

    const newLines = newText.split('\n').length;
    if (newLines > maxRows) {
      setRowsArea(maxRows);
    } else {
      setRowsArea(Math.max(minRows, newLines));
    }
  };

  const handleKeyPress = (event) => {
    if (text.length >= CHARACTER_LIMIT && event.key !== 'Backspace') {
      event.preventDefault();
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey && text) {
      // Prevent default form submission behavior
      event.preventDefault();
      handleSubmit(event.target.value);
      if (clearOnSubmit) {
        setText('');
      }
      setRowsArea(minRows);
      return;
    }
  };

  return (
    <div style={{ position: 'sticky', width: '100%' }}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'flex-start' }}>
        <textarea
          value={text}
          rows={rowsArea}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder={placeholder || 'Enter your query here. Press Enter to submit.'}
          disabled={disabled}
          style={{
            marginRight: '8px',
            padding: '18px',
            fontSize: '14px',
            borderRadius: '16px',
            border: '1px solid #ccc',
            boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
            boxSizing: 'border-box',
            resize: 'none',
            flex: 1, // Take up remaining space in the flex container
            ...style,
          }}
        />
        {/* <button
          type="submit"
          style={{
            padding: '8px',
            fontSize: '16px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            backgroundColor: '#f0f0f0',
          }}
        >
          Submit
        </button> */}
      </form>
      {text.length >= CHARACTER_LIMIT && <div style={{ color: 'red' }}>Number of characters reached</div>}
    </div>
  );
};
