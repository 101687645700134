import React, { useState, useEffect } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { loremIpsum } from '../utils/contentData';

/**
 * The audio transcription is going to come from the API or in real time with a React library
 * @param {*}
 * @returns
 */
export const AudioSnippet = ({ label, setDisplayText }: { label?: string; setDisplayText }) => {
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const [transcription, setTranscription] = useState('');
  // const [, setAudioElement] = useState(undefined);
  const [index, setIndex] = useState(0);

  // typewriting effect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (index === transcription.length) {
        clearTimeout(timeoutId);
        return;
      }
      setDisplayText((prevText) => prevText + transcription.charAt(index));
      setIndex((prevIndex) => prevIndex + 1);
    }, 20);
  }, [transcription, setDisplayText, index]);

  const addAudioElement = (blob) => {
    setIndex(0);

    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    // setAudioElement(audio)
    // var transcriptionContainer = document.getElementById("transcription-container");
    // transcriptionContainer.innerHTML = '';
    // transcriptionContainer.appendChild(audio);

    // TODO: get the transcription from the API
    const audioTranscription = loremIpsum;
    setTranscription(audioTranscription);
  };
  return (
    <div>
      <div>
        <AudioRecorder
          onRecordingComplete={(blob) => addAudioElement(blob)}
          recorderControls={recorderControls}
          // downloadOnSavePress={true}
          // downloadFileExtension="mp3"
          showVisualizer={true}
        />
        {label ? <div style={{ marginLeft: 12 }}>{label}</div> : null}
      </div>

      <br />
      {/* <button onClick={recorderControls.startRecording}>Start recording</button> */}
      {/* <button onClick={recorderControls.stopRecording}>Stop recording</button> */}
      {/* <div id="transcription-container"></div> */}
      {/* <div>{displayText}</div> */}
    </div>
  );
};
