/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { FileType } from '../../networking/usePostPDF';
import { isNonEmpty } from '../../utils/arrayUtils';
import { TextForm } from '../../components/base/TextForm';
import { ReportReview, usePostReview } from '../../networking/usePostReview';
import Loading from '../../components/base/Loading';
import { useSessionState } from '../../utils/usePersistedState';
import { fromReviewResponseToReportResultNode, useGetAnomalies } from './useGetAnomalies';
import { Text } from '../../components/base/Text';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Button } from '../../components/base/Button';
import { ReportAnomalies } from './ReportAnomalies';

export const ReviewTitle = ({ title, dropdDownItems = null }: { title: string; dropdDownItems? }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: 10 }}>
      <Text value={title} style={{ fontSize: '1.4vw', fontWeight: 700 }} />
      {dropdDownItems && (
        <Dropdown menu={{ items: dropdDownItems }}>
          <DownOutlined />
        </Dropdown>
      )}
    </div>
  );
};

export const TextReview = ({
  files,
  highlights,
  setHighlights,
  dropdDownItems,
}: {
  files: FileType[];
  highlights;
  setHighlights;
  dropdDownItems;
}) => {
  const { isLoading: isLoadingTextReview, data: postReviewResponse, call: callStartReview } = usePostReview();
  const [currentReview, setCurrentReview] = useSessionState<ReportReview>('currentTextReview', null);
  const [query, setQuery] = useSessionState<string>('currentReviewQuery', '');

  const { reportResult, setReportResult } = useGetAnomalies({
    reviewResponse: postReviewResponse,
    setReviewResponse: setCurrentReview,
    setKnwoledgeBaseHighlights: setHighlights,
  });

  /**
   * After the files to review have been uploaded,
   * start the actual review automatically
   */
  useEffect(() => {
    if (currentReview) {
      const reportResultNode = fromReviewResponseToReportResultNode({
        reviewResponse: currentReview,
      });
      setReportResult(reportResultNode.anomalyHighlights);
    }
  }, []);

  const submitQuery = async (query) => {
    if (isNonEmpty(files)) {
      callStartReview({ text_to_review: query, knowledge_base: files.map((f) => f.id) });
      setQuery(query);
    }
  };

  useEffect(() => {
    if (reportResult) {
      const kbHighlights = reportResult.map((a) => a.highlights);
      setHighlights(kbHighlights[0]);
    }
  }, [reportResult]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <ReviewTitle title="Text to review" dropdDownItems={dropdDownItems} />
        {isNonEmpty(highlights) && (
          <Button
            text="Exit review"
            style={{ width: 150, backgroundColor: 'yellow' }}
            onClick={() => {
              setHighlights([]);
              setReportResult(null);
              setCurrentReview(null);
            }}
          />
        )}
      </div>

      <div style={{ paddingTop: 32 }}>
        <TextForm
          disabled={!isNonEmpty(files) && !isLoadingTextReview}
          initialText={query !== 'undefined' ? query : ''}
          placeholder="Enter your text here. Press Enter to start the review."
          handleSubmit={submitQuery}
          minRows={15}
          maxRows={20}
          characterLimit={2048}
          clearOnSubmit={false}
        ></TextForm>
        <div className="review-result-container">
          {isLoadingTextReview ? (
            <Loading text="Reviewing your text..." onlyText={true} style={{ fontSize: 14 }} />
          ) : reportResult ? (
            <div style={{ flexGrow: 1, paddingTop: 10 }}>
              <ReportAnomalies reportHighlights={reportResult} setKBHighlights={setHighlights}></ReportAnomalies>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
