import React, { CSSProperties, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export const TextArea = ({
  text,
  minLines = 4,
  maxLines = 8,
  setText,
  style,
}: {
  text: string;
  minLines?: number;
  maxLines?: number;
  setText: Dispatch<SetStateAction<string>>;
  style?: CSSProperties;
}) => {
  // Move the display text to Section?
  const [rowsArea, setRowsArea] = useState(minLines);
  const textAreaRef = useRef(null);
  const CHARACTER_LIMIT = 20000;

  const handleInputChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    const newLines = newText.split('\n').length;
    if (minLines <= newLines && newLines <= maxLines) {
      setRowsArea(newLines);
    }
  };

  const handleKeyPress = (event) => {
    if (text.length >= CHARACTER_LIMIT && event.key !== 'Backspace') {
      event.preventDefault();
      return;
    }
  };

  return (
    <textarea
      ref={(el) => (textAreaRef.current = el)}
      value={text}
      rows={rowsArea}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      placeholder="Enter text here."
      style={
        style || {
          marginRight: '8px',
          padding: '18px',
          fontSize: '14px',
          borderRadius: '10px',
          border: '1px solid #ccc',
          boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
          boxSizing: 'border-box',
          flex: 1, // Take up remaining space in the flex container
        }
      }
    />
  );
};
