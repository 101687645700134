import React, { useState } from 'react';
import { PdfHighlight, SearchablePDFViewer } from './SearchablePDFViewer';
import FileDrop from './FileDrop';
import { ImageFileType } from '../../networking/usePostImageClassification';
import { GalleryImages } from '../images/ImageGallery';
import { HeaderTabs } from '../base/HeaderTabs';
import { FileType } from '../../networking/usePostPDF';
import { HighlightedPDFViewer } from './HighlightedPDFViewer';
import { FileList } from './FileList';
import { isNonEmpty } from '../../utils/arrayUtils';

export const FileContainer = ({
  showSearchablePDFViewer,
  showHeaderTabs = true,
  enableSampleDocument = true,
  documents,
  setDocuments,
  onDeleteDocument,
  currentIndex,
  setCurrentIndex,
  images,
  setImages,
  resetFiles,
  highlights,
  setHighlights,
  fileDropText,
  uploadMultiple,
  accept,
}: {
  showSearchablePDFViewer: boolean;
  showHeaderTabs?: boolean;
  enableSampleDocument?: boolean;
  documents: FileType[];
  setDocuments;
  onDeleteDocument?;
  currentIndex?;
  setCurrentIndex?;
  images?: ImageFileType[];
  setImages?;
  resetFiles?;
  highlights?: PdfHighlight[];
  setHighlights?;
  fileDropText?: string;
  uploadMultiple: boolean;
  accept?: string;
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const setSampleFile = async () => {
    const fileUrl = 'https://relyant-public-assets.s3.us-west-2.amazonaws.com/sample0.pdf';
    setDocuments([{ url: fileUrl, filename: 'sample0.pdf' }]);
    setCurrentIndex(0);
  };

  const tabs = [
    {
      id: 'tab-reset',
      label: 'Reset',
      onClick: resetFiles,
    },
  ];

  const haveDocuments = isNonEmpty(documents);
  const haveImages = isNonEmpty(images);

  if (haveImages) {
    tabs.unshift({
      id: 'tab-images',
      label: 'Images',
      onClick: undefined,
    });
  }

  if (haveDocuments) {
    tabs.unshift({
      id: 'tab-pdfs',
      label: 'PDFs',
      onClick: undefined,
    });
  }

  return (
    <>
      {haveImages || haveDocuments ? (
        <div style={{ width: '100%' }}>
          {showHeaderTabs && (
            <HeaderTabs tabs={tabs} currentTabIndex={currentTabIndex} setCurrentTabIndex={setCurrentTabIndex} />
          )}

          {haveDocuments && tabs[currentTabIndex].id === 'tab-pdfs' ? (
            <div>
              <FileList
                documents={documents}
                setDocuments={setDocuments}
                indexDocument={currentIndex}
                setIndexDocument={setCurrentIndex}
                onDeleteFile={onDeleteDocument}
              />
              {showSearchablePDFViewer && isNonEmpty(highlights) ? (
                <div style={{ textAlign: 'center' }}>
                  <HighlightedPDFViewer fileUrl={documents[currentIndex].url} highlights={highlights} />
                </div>
              ) : showSearchablePDFViewer ? (
                <div style={{ textAlign: 'center' }}>
                  <SearchablePDFViewer fileUrl={documents[currentIndex].url} />
                </div>
              ) : null}
            </div>
          ) : haveImages && tabs[currentTabIndex].id === 'tab-images' ? (
            <GalleryImages images={images} includeFileNames={true}></GalleryImages>
          ) : null}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FileDrop
            text={fileDropText || 'Click or drag inspection documents to this area to upload'}
            setFiles={setDocuments}
            setImages={setImages}
            multiple={uploadMultiple}
            accept={accept}
            styleText={{ fontSize: 16, fontWeight: 500 }}
          />
          {enableSampleDocument && (
            <div>
              <div style={{ marginTop: 8 }}>Or use our sample files:</div>
              <div
                className="clickable"
                style={{ color: 'blue', marginTop: 8 }}
                onClick={setSampleFile}
                role="button"
                onKeyDown={() => {}}
                tabIndex={0}
              >
                Sample document
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
