import React, { useEffect } from 'react';
import { FileType } from '../../networking/usePostPDF';
import { ReviewTitle } from '../../views/ReportReview/TextReview';
import FileDrop from './FileDrop';
import { FileList } from './FileList';
import { isNonEmpty } from '../../utils/arrayUtils';
import { useGetDocuments } from '../../networking/useGetDocuments';
import { useSessionState } from '../../utils/usePersistedState';
import { CheckBox } from '../base/CheckBox';

type SourcesDocument = FileType & {
  isSelected?: boolean;
};

export const SourcesView = ({
  title = 'Sources',
  textUpload,
  documents,
  setDocuments,
  onViewDocument,
  onDeleteDocument,
}: {
  title?: string;
  textUpload?: string;
  documents: SourcesDocument[];
  setDocuments: (docs) => void;
  onViewDocument?: (document: FileType) => void;
  onDeleteDocument?: (deleteResponse) => void;
}) => {
  const [useKBDocuments, setUseKBDocuments] = useSessionState<boolean>('useKBDocuments', false);
  const [kbDocuments, setKBDocuments] = useSessionState<FileType[]>('kbDocuments', []);
  const { data: documentsResponse, call: callGetDocuments } = useGetDocuments({
    document_ids: [],
  });

  /**
   * Fetch all the documents of the user when the page is rendered
   */
  useEffect(() => {
    callGetDocuments();
  }, []);

  useEffect(() => {
    if (documentsResponse && documentsResponse.items) {
      // some documents might have been deleted in other tabs
      // if there are documents that are no more present in the DB, they should be deleted from the UI
      const userDocuments = documentsResponse.items.map((doc) => doc.id);
      const nonDeletedDocuments = documents.filter((doc) => userDocuments.includes(doc.id));
      setDocuments(nonDeletedDocuments);

      setKBDocuments(
        documentsResponse.items.map((doc) => {
          return { ...doc, isExcluded: false };
        })
      );
    }
  }, [documentsResponse]);

  const onDelete = (deleteResponse) => {
    setDocuments(documents.filter((doc) => !deleteResponse.includes(doc.id)));
    setKBDocuments(kbDocuments.filter((doc) => !deleteResponse.includes(doc.id)));
    if (onDeleteDocument) {
      onDeleteDocument(deleteResponse);
    }
  };

  const addKBDocuments = () => {
    const documentIds = documents.map((doc) => doc.id);
    const kbDocumentsOnly = kbDocuments.filter((doc) => !documentIds.includes(doc.id));
    setDocuments([...documents, ...kbDocumentsOnly]);
    setUseKBDocuments(true);
  };

  const removeKBDocuments = () => {
    const kbDocumentIds = kbDocuments.map((doc) => doc.id);
    const documentsOnly = documents.filter((doc) => !kbDocumentIds.includes(doc.id));
    setDocuments(documentsOnly);
    setUseKBDocuments(false);
  };

  return (
    <>
      {isNonEmpty(documents) ? (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <ReviewTitle title={title}></ReviewTitle>
            <div>
              <FileDrop
                useButton={true}
                text=""
                loadingText="Loading..."
                multiple={true}
                setFiles={(docs) => setDocuments([...documents, ...docs])}
                loadingOnlyText={true}
              />
            </div>
          </div>

          <div style={{ paddingTop: 24 }}>
            <div style={{ borderBottom: '1px solid #ccc', paddingBottom: 8 }}>
              <CheckBox
                text={'Use previous supporting documents'}
                checked={useKBDocuments}
                onChange={() => (useKBDocuments ? removeKBDocuments() : addKBDocuments())}
              />
            </div>
            <FileList
              documents={documents}
              setDocuments={setDocuments}
              onViewDocument={onViewDocument}
              onDeleteFile={onDelete}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FileDrop
            text={textUpload || 'Upload supporting documents here\n(SOP, industry standard, regulations)'}
            setFiles={(docs) => {
              setDocuments(docs);
            }}
            multiple={true}
            accept={'application/pdf'}
            styleText={{ fontSize: 16, fontWeight: 500 }}
            skipSection={
              isNonEmpty(kbDocuments)
                ? {
                    text: 'use existing documents',
                    onClick: () => {
                      setDocuments(kbDocuments);
                      setUseKBDocuments(true);
                    },
                  }
                : null
            }
          />
        </div>
      )}
    </>
  );
};
