export const loremIpsum =
  'The pressure of the horizontal furnace is 24 Pa. The pressure has been measured using a standard barometer.';

export const initialSections = [
  {
    title: 'Title of section 1',
    requirement: 'requirement',
    checklist: [],
  },
  {
    title: 'Title of section 2',
    requirement: 'requirement',
    checklist: [],
  },
];

export const intialHeaderState = {
  title: 'Title Report',
  writer: 'Luca Giacomelli',
};
