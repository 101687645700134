import React, { CSSProperties, useEffect } from 'react';

import { FileType } from '../../networking/usePostPDF';
import { useDeleteDocuments } from '../../networking/useDeleteDocuments';
import { DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { CheckBox } from '../base/CheckBox';

const MAX_FILENAME_LENGTH = 36;

const FileEntry = ({
  document,
  onChangeDocument,
  index,
  setIndexDocument,
  onDelete,
  onView,
  viewMode = false,
  style,
}: {
  document: FileType;
  onChangeDocument?;
  index?: number;
  setIndexDocument?;
  onDelete?;
  onView?;
  viewMode?: boolean;
  style?;
}) => {
  const { data: deleteResponse, call: callDeleteDocuments } = useDeleteDocuments();
  useEffect(() => {
    if (deleteResponse && onDelete) {
      onDelete(deleteResponse);
    }
  }, [deleteResponse]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        minHeight: 32,
        flexDirection: 'row',
        padding: 8,
        alignItems: 'center',
        ...style,
      }}
    >
      <div style={{ width: '80%', fontSize: 14 }}>
        {document.filename.length > MAX_FILENAME_LENGTH
          ? `${document.filename.substring(0, MAX_FILENAME_LENGTH)}...`
          : document.filename}
      </div>
      <div style={{ display: 'flex', width: '20%', flexDirection: 'row', justifyContent: 'space-around' }}>
        <CheckBox
          tooltip="Include in review"
          checked={!document.isExcluded}
          onChange={() => {
            if (onChangeDocument) onChangeDocument(document);
          }}
        ></CheckBox>
        <Tooltip className="clickable" title="View">
          <EyeOutlined
            onClick={() => {
              if (onView) {
                onView(document);
              }
              if (setIndexDocument) {
                setIndexDocument(viewMode ? null : index);
              }
            }}
            style={{}}
          />
        </Tooltip>
        <Tooltip className="clickable" title="Delete">
          <DeleteFilled style={{ color: 'red' }} onClick={() => callDeleteDocuments({ entity_ids: [document.id] })} />
        </Tooltip>
      </div>
    </div>
  );
};

export const FileList = ({
  documents,
  setDocuments,
  indexDocument = 0,
  setIndexDocument,
  onViewDocument,
  onDeleteFile,
  style,
}: {
  documents: FileType[];
  setDocuments;
  indexDocument?: number;
  setIndexDocument?;
  onViewDocument?: (document: FileType) => void;
  onDeleteFile?;
  style?: CSSProperties;
}) => {
  const onChangeDocument = (document: FileType) => {
    setDocuments(documents.map((doc) => (doc.id === document.id ? { ...doc, isExcluded: !document.isExcluded } : doc)));
  };

  return (
    <div style={{ overflowY: 'auto', ...style }}>
      {documents.length > 0 &&
        documents.map((doc, index) => {
          return (
            <FileEntry
              key={index}
              document={doc}
              onChangeDocument={onChangeDocument}
              index={index}
              setIndexDocument={setIndexDocument}
              onView={onViewDocument}
              onDelete={onDeleteFile}
              viewMode={indexDocument === index}
              // style={{ borderBottom: index !== documents.length - 1 ? '1px solid #ccc' : 'none' }}
            ></FileEntry>
          );
        })}
    </div>
  );
};
