export type base64File = {
  base64String: string;
  mimeType: string;
  fileName?: string;
};

export const toBase64 = (file: File | Blob): Promise<base64File> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        mimeType: (reader.result as string).split(',')[0],
        base64String: (reader.result as string).split(',')[1],
      });
    reader.onerror = reject;
  });

export const base64ToFile = (base64File: base64File, filename) => {
  // Remove the base64 URL prefix if it exists
  const base64Prefix = ';base64,';
  let base64String = `${base64File.mimeType},${base64File.base64String}`;

  const parts = base64String.split(base64Prefix);
  base64String = parts[1];

  const byteString = atob(base64String);

  // Convert byteString to an array of 8-bit unsigned integers
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Determine MIME type from the base64 prefix
  const mimeType = parts[0].split(':')[1];

  // Create a Blob from the byte array
  const blob = new Blob([byteArray], { type: mimeType });

  // Create a File from the Blob
  return new File([blob], filename, { type: mimeType });
};
