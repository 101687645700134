import { Tooltip } from 'antd';
import React from 'react';

export const NumberedDot = ({ text, tooltip, onClick }: { text: number; tooltip?; onClick }) => {
  return (
    <Tooltip title={tooltip}>
      <span
        className="clickable"
        onClick={onClick}
        style={{
          display: 'inline-block',
          textAlign: 'center',
          alignItems: 'center',
          height: 20,
          width: 20,
          marginLeft: 8,
          borderRadius: '50%',
          backgroundColor: '#1890ff',
          color: 'white',
        }}
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
      >
        {text}
      </span>
    </Tooltip>
  );
};
