import React, { useEffect, useRef } from 'react';
import Message, { MessageType, SenderEnum } from './Message';

export type ChatRecommendation = {
  icon?: unknown;
  text: string;
  onClick?: () => void;
};

const ChatWindow = ({
  messages,
  recommendations = [],
  isLoading = false,
}: {
  messages: MessageType[];
  recommendations?: ChatRecommendation[];
  isLoading?: boolean;
}) => {
  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: '10px',
        paddingBottom: '10px',
        overflow: 'auto',
        backgroundColor: '#f4f4f4',
      }}
    >
      {messages.length < 1 ? (
        <div style={{ margin: 'auto', display: 'flex', flexWrap: 'wrap', gap: 30 }}>
          {recommendations.map((rec, index) => (
            <div
              key={index}
              className="chat-recommendation clickable"
              onClick={rec.onClick}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              {rec.text}
            </div>
          ))}
        </div>
      ) : null}
      {messages.map((msg, index) => (
        <Message key={index} message={msg} />
      ))}
      {isLoading && <Message message={{ text: 'Thinking...', sender: SenderEnum.bot }} />}
      <div ref={chatEndRef} />
    </div>
  );
};

export default ChatWindow;
