import React, { ReactNode } from 'react';
import { Sentence } from '../../networking/useQueryDocument';
import Feedback, { FeedbackType } from './Feedback';

export enum SenderEnum {
  user = 'user',
  bot = 'bot',
}

export type MessageType = {
  messageId?: string;
  text: string | ReactNode;
  sender: SenderEnum;
  feedback?: FeedbackType;
  onFeedback?: (type) => void;
};

export type ChatMessage = {
  messageId?: string;
  sentences: Sentence[];
  sender: SenderEnum;
  feedback?: FeedbackType;
  onFeedback?: (type) => void;
};

const Message = ({ message }: { message: MessageType }) => {
  const { messageId, text, sender, feedback, onFeedback } = message;

  return (
    <>
      <div style={{ ...styles.message, ...(sender === 'user' ? styles.user : styles.bot) }}>{text}</div>
      {onFeedback && <Feedback value={feedback} onFeedback={onFeedback}></Feedback>}
    </>
  );
};

const styles = {
  message: {
    maxWidth: '70%',
    margin: '10px 0',
    padding: '10px',
    borderRadius: '10px',
    fontSize: '14px',
  },
  user: {
    alignSelf: 'flex-end',
    backgroundColor: '#0084ff',
    color: '#fff',
  },
  bot: {
    alignSelf: 'flex-start',
    backgroundColor: '#e5e5ea',
    color: '#000',
  },
};

export default Message;
